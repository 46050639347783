import React, { useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Steps } from 'antd';
import BuilderType from './component/BuilderType';
import BuilderBrand from './component/BuilderBrand';
import BuilderDesign from './component/BuilderDesign';
import BuilderContent from './component/BuilderContent';
import BuilderDeploy from './component/BuilderDeploy';
import Breadcrumb from '../../component/Breadcrumb';
import reducer from './service/Reducer';
import './WebsiteCreate.scss';
import { ThemeProvider } from '../../component/editor/ThemeProvider';

const steps = [
  {
    key: 'type',
    title: 'Website Türü',
  },
  {
    key: 'brand',
    title: 'Bilgiler',
  },
  {
    key: 'design',
    title: 'Tasarım',
  },
  {
    key: 'content',
    title: 'İçerikler',
  },
  {
    key: 'deploy',
    title: 'Yayınlayın',
  },
];

export default function WebsiteCreate() {
  const params = useParams();
  const paramStep = steps.findIndex((step) => step.key === params.key);
  const [current, setCurrent] = useState(paramStep >= 0 ? paramStep : 0);
  const [percent, setPercent] = useState(0);

  const [state, dispatch] = useReducer(reducer, {
    title: '',
    type: '',
    link: '',
    logo: '',
    logoDark: '',
    page: [
      'about',
      'contact',
    ],

    favicon: '',

    language: 'tr',

    target: '',
    slogan: '',
    purpose: '',

    contactEmail: '',
    contactPhone: '',
    contactAddress: '',

    colorPrimary: '#02394A',
    colorText: '#050505',
    colorBackground: '#ffffff',

    colorSuccess: '#b7eb8f',
    colorError: '#ffccc7',
    colorWarning: '#ffe58f',
    colorInfo: '#91caff',

    socialMedia: {},

    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: 16,
  });

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <div style={{ padding: 16 }}>
        <Breadcrumb
          items={[
            {
              title: 'Website Oluştur',
            },
          ]}
          style={{ margin: 0 }}
        />
      </div>
      <div
        style={{
          padding: '0 16px',
          background: '#efefef',
        }}
      >
        <Steps items={steps} current={current} percent={percent} status="process" direction="horizontal" size="small" type="navigation" />
      </div>
      <ThemeProvider projectId="1" themeDefault="light">
        <div
          style={{
            padding: 16,
            background: '#fff',
          }}
        >
          {current === 0 && (
            <BuilderType state={state} dispatch={dispatch} onComplete={() => next()} onPrevious={() => prev()} setPercent={setPercent} />
          )}
          {current === 1 && (
            <BuilderBrand state={state} dispatch={dispatch} onComplete={() => next()} onPrevious={() => prev()} setPercent={setPercent} />
          )}
          {current === 2 && (
            <BuilderDesign state={state} dispatch={dispatch} onComplete={() => next()} onPrevious={() => prev()} setPercent={setPercent} />
          )}
          {current === 3 && (
            <BuilderContent state={state} dispatch={dispatch} onComplete={() => next()} onPrevious={() => prev()} setPercent={setPercent} />
          )}
          {current === 4 && (
            <BuilderDeploy state={state} dispatch={dispatch} onComplete={() => next()} onPrevious={() => prev()} setPercent={setPercent} />
          )}
        </div>
      </ThemeProvider>
    </>
  );
}
