import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header8Props {
  logo: {
    src: string;
    alt: string;
  };
  navigation: {
    text: string;
    url: string;
  }[];
  searchPlaceholder: string;
}

const config: EditorConfigW<Header8Props> = {
  title: 'Header 8 - Arama Çubuklu',
  description: 'Hamburger menü ve arama çubuğu içeren header',
  type: 'header-8',
  code: 'HDR8',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#f8f9fa',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'navigation', 'searchPlaceholder'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Şirket Logo' },
        },
      },
      navigation: {
        type: 'array',
        title: 'Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
      searchPlaceholder: {
        type: 'string',
        title: 'Arama Çubuğu Yer Tutucu',
        default: 'Arayın...',
      },
    },
  },
  propsDefault: async (project: IProject) => {
    return {
      logo: {
        src: project.logo || '/logo.svg', // Projeden logo al
        alt: project.title || 'Şirket Logo', // Proje başlığını alt metin olarak al
      },
      navigation: [
        { text: 'Hakkımızda', url: '#' },
        { text: 'Hizmetler', url: '#' },
        { text: 'İletişim', url: '#' },
      ],
      searchPlaceholder: 'Arayın...', // Arama kutusunun varsayılan placeholder değeri
    };
  },
};

export default config;
