import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header12Props {
  logo: {
    src: string;
    alt: string;
  };
  navigation: {
    text: string;
    url?: string;
    submenu?: {
      title: string;
      links: {
        text: string;
        url: string;
      }[];
    }[];
  }[];
  buttons: {
    text: string;
    url: string;
    primary?: boolean;
  }[];
}

const config: EditorConfigW<Header12Props> = {
  title: 'Header 12 - Mega Menü',
  description: 'Mega menü ve butonlar içeren header',
  type: 'header-12',
  code: 'HDR12',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#ffffff',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'navigation', 'buttons'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      navigation: {
        type: 'array',
        title: 'Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
            submenu: {
              type: 'array',
              title: 'Alt Menü',
              items: {
                type: 'object',
                properties: {
                  title: { type: 'string', title: 'Alt Menü Başlığı' },
                  links: {
                    type: 'array',
                    title: 'Bağlantılar',
                    items: {
                      type: 'object',
                      properties: {
                        text: { type: 'string', title: 'Bağlantı Metni' },
                        url: { type: 'string', title: 'Bağlantı URL' },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      buttons: {
        type: 'array',
        title: 'Butonlar',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Buton Metni' },
            url: { type: 'string', title: 'Buton URL' },
            primary: { type: 'boolean', title: 'Ana Buton mu?' },
          },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    navigation: [
      {
        text: 'Ürünler',
        submenu: [
          {
            title: 'Kategori 1',
            links: [
              { text: 'Ürün 1', url: '#' },
              { text: 'Ürün 2', url: '#' },
            ],
          },
          {
            title: 'Kategori 2',
            links: [
              { text: 'Ürün 3', url: '#' },
              { text: 'Ürün 4', url: '#' },
            ],
          },
        ],
      },
      { text: 'Hakkımızda', url: '#' },
      { text: 'İletişim', url: '#' },
    ],
    buttons: [
      { text: 'Giriş Yap', url: '#', primary: false },
      { text: 'Kayıt Ol', url: '#', primary: true },
    ],
  }),
};

export default config;
