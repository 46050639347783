import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { notification } from 'antd';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IError, IPage, IPageBlock, IProject } from '../../service/Type';
import { loadProject } from '../../service/api/Project';
import { loadPage, pageUpdate } from '../../service/api/Page';
import Breadcrumb from '../../component/Breadcrumb';
import Editor from '../../component/editor/Editor';
import { RiPagesLine } from 'react-icons/ri';
import { GrBlog, GrWifiNone } from 'react-icons/gr';
import { IoIosContact, IoIosInformationCircleOutline } from 'react-icons/io';
import { IoHomeOutline } from 'react-icons/io5';
import './WebsitePageEditor.scss';

export default function EditorModule() {
  const { projectId, pageId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [project, setProject] = useState<IProject>();
  const [page, setPage] = useState<IPage>();

  const loadProjectDetail = async () => {
    const projectResult = await loadProject(projectId as string);
    if (projectResult.result) {
      await setProject(projectResult.result);
    }
    if (projectResult.errors) {
      await setError(projectResult.errors);
    }
  };

  const loadPageDetail = async () => {
    const pageResult = await loadPage(pageId as string);
    if (pageResult.result) {
      await setPage(pageResult.result);
    }
    if (pageResult.errors) {
      await setError(pageResult.errors);
    }
  };

  const load = async () => {
    await setLoading(true);
    await loadProjectDetail();
    await loadPageDetail();
    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, [projectId, pageId]);

  if (loading) {
    return <div className="page">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="page">Hata</div>;
  }

  if (!project) {
    return <div className="page">Hata</div>;
  }

  const schema = yup.object().shape({
    title: yup.string().required('Sayfa adı girilmelidir').max(256, 'Sayfa adı en fazla 256 karakter uzunluğunda olabilir'),
  });

  const onSubmit = async (values: any) => {
    console.log(values);

    await pageUpdate(pageId as string, {
      title: values.title,
      pageBlocks: values.pageBlocks,
    });

    notification.success({
      message: 'Başarı',
      description: 'Sayfa başarıyla kaydedildi. ',
    });
  };

  const initialValues = {
    title: page?.title as string,
    pageBlocks: (page?.pageBlocks as IPageBlock[]) || [],
  };

  return (
    <>
      <Helmet>
        <title>Sayfa Düzenle | {project?.title}</title>
      </Helmet>
      <div className="editor-breadcrumb">
        <Breadcrumb
          items={[
            {
              title: <Link to={`/project/${projectId}`}>{project?.title}</Link>,
            },
            {
              title: <span style={{ cursor: 'pointer' }}>{page?.title}</span>,
              menu: {
                selectedKeys: [pageId as string],
                items: [
                  ...(project?.pageList as IPage[]).map((page) => {
                    let icon;
                    switch (page.type) {
                      case 'home':
                        icon = <IoHomeOutline style={{ display: 'inline-block' }} />;
                        break;
                      case 'blog':
                      case 'blog-page':
                        icon = <GrBlog style={{ display: 'inline-block' }} />;
                        break;
                      case 'contact':
                        icon = <IoIosContact style={{ display: 'inline-block' }} />;
                        break;
                      case 'about':
                        icon = <IoIosInformationCircleOutline style={{ display: 'inline-block' }} />;
                        break;
                      case 'error-not-found':
                        icon = <GrWifiNone style={{ display: 'inline-block' }} />;
                        break;
                      default:
                        icon = <RiPagesLine style={{ display: 'inline-block' }} />;
                        break;
                    }

                    return {
                      key: page.pageId as string,
                      title: (
                        <>
                          {icon}
                          <span> </span>
                          <Link to={`/project/${projectId}/sayfa/${page.pageId}/duzenle`}>{page.title}</Link>
                        </>
                      ),
                      items: [],
                    };
                  }),
                ],
              },
              dropdownProps: {
                arrow: true,
                placement: 'bottomRight',
              },
            },
          ]}
          style={{ margin: 0 }}
        />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          await onSubmit(values);
          return true;
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
          <Editor
            pageId={pageId as string}
            project={project as IProject}
            page={page as IPage}
            pageBlocks={values.pageBlocks}
            updatePageBlocks={(pageBlocks: IPageBlock[]) => setFieldValue('pageBlocks', pageBlocks)}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            handleUpdate={load}
          />
        )}
      </Formik>
    </>
  );
}
