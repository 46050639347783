import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Footer4Props {
  logo: {
    src: string;
    alt: string;
  };
  description: string;
}

const config: EditorConfigW<Footer4Props> = {
  title: 'Footer 4 - Sosyal Medya Odaklı',
  description: 'Logo, açıklama ve sosyal medya ikonları içeren footer',
  type: 'footer-4',
  code: 'FTR4',
  category: ['footer'],
  suitable: ['footer'],
  styleDefault: {
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'description'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      description: { type: 'string', title: 'Açıklama', default: 'Şirket açıklaması buraya gelecek.' },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    description: `${project.title || 'Şirket Adı'} hakkında kısa bir açıklama.`,
  }),
};

export default config;
