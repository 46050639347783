import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { WidgetProps } from '@rjsf/utils';
import { imageUpload } from '../../../service/api/Image';
import { ThemeProvider, useTheme } from "../ThemeProvider";
import './MultiUploadWidget.scss';

export default function MultiUploadWidget(props: WidgetProps) {
  const { projectId } = useTheme();

  const [fileList, setFileList] = useState<UploadFile[]>(
    props.value?.map((url: string, urlIndex: number) => ({
      uid: String(urlIndex),
      url: url,
      name: url,
      status: 'done',
    })),
  );

  console.log(props.value);
  console.log(fileList);

  const [preview, setPreview] = useState('');

  const onChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    setFileList(
      newFileList.map((fileList) => ({
        ...fileList,
        url: fileList.url || fileList.response,
      })),
    );
    console.log(newFileList.map((fileList) => fileList.url));
    props.onChange(newFileList.map((fileList) => fileList.url));
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as any);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    setPreview(src);
  };

  const customRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;

    try {
      const src = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
      });

      const imageUploadResult = await imageUpload(projectId, src, file.type as string, file.name);

      console.log(imageUploadResult);

      if (onSuccess) {
        onSuccess(imageUploadResult.result?.path, file);
      }
    } catch (err) {
      onError(err);
    }
  };

  return (
    <div className="image-upload-widget">
      <ThemeProvider themeDefault="light" projectId={projectId}>
        {props?.uiSchema?.crop ? (
          <ImgCrop
            rotationSlider
            quality={90}
            aspect={props?.uiSchema?.width && props?.uiSchema?.height ? props?.uiSchema?.width / props?.uiSchema?.height : undefined}
            showGrid
            showReset
          >
            <Upload
              // action={`${process.env.REACT_APP_URL_API}/image`}
              listType={props?.uiSchema?.picture || 'picture-card'}
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              customRequest={customRequest}
              // headers={{
              //  Authorization: localStorage.getItem('token') as string,
              // }}
              multiple
              maxCount={100}
            >
              <span>Görsel Seç</span>
            </Upload>
          </ImgCrop>
        ) : (
          <Upload
            // action={`${process.env.REACT_APP_URL_API}/image`}
            listType={props?.uiSchema?.picture || 'picture-card'}
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            customRequest={customRequest}
            // headers={{
            //  Authorization: localStorage.getItem('token') as string,
            // }}
            multiple
            maxCount={100}
          >
            <span>Görsel Seç</span>
          </Upload>
        )}
        <Modal open={!!preview}>
          <img src={preview} alt="" />
        </Modal>
      </ThemeProvider>
    </div>
  );
}
