import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header9Props {
  logo: {
    src: string;
    alt: string;
  };
  topLinks: {
    text: string;
    url: string;
  }[];
  navigation: {
    text: string;
    url: string;
  }[];
  buttons: {
    text: string;
    url: string;
    primary?: boolean;
  }[];
  contactInfo: {
    phone: string;
    email: string;
  };
}

const config: EditorConfigW<Header9Props> = {
  title: 'Header 9 - Çift Katmanlı',
  description: 'Çift katmanlı header tasarımı, iletişim bilgileri ve butonlar içerir.',
  type: 'header-9',
  code: 'HDR9',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#ffffff',
    color: '#333333',
  },
  schema: {
    type: 'object',
    required: ['logo', 'topLinks', 'navigation', 'buttons', 'contactInfo'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      topLinks: {
        type: 'array',
        title: 'Üst Linkler',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Link Metni' },
            url: { type: 'string', title: 'Link URL' },
          },
        },
      },
      navigation: {
        type: 'array',
        title: 'Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
      buttons: {
        type: 'array',
        title: 'Butonlar',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Buton Metni' },
            url: { type: 'string', title: 'Buton URL' },
            primary: { type: 'boolean', title: 'Ana Buton mu?' },
          },
        },
      },
      contactInfo: {
        type: 'object',
        title: 'İletişim Bilgileri',
        properties: {
          phone: { type: 'string', title: 'Telefon' },
          email: { type: 'string', title: 'E-posta' },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => {
    return {
      logo: {
        src: project.logo || '/logo.svg',
        alt: project.title || 'Logo',
      },
      topLinks: [
        { text: 'Blog', url: '#' },
        { text: 'Yardım', url: '#' },
      ],
      navigation: [
        { text: 'Hakkımızda', url: '#' },
        { text: 'Hizmetler', url: '#' },
        { text: 'İletişim', url: '#' },
      ],
      buttons: [
        { text: 'Giriş Yap', url: '#', primary: false },
        { text: 'Ücretsiz Başla', url: '#', primary: true },
      ],
      contactInfo: {
        phone: project.contactPhone || '+90 312 123 45 67',
        email: project.contactEmail || 'info@example.com',
      },
    };
  },
};

export default config;
