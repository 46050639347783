import { EditorConfigW } from '../../../../service/Type';
import { AlertBaseProps } from '../alert/types';

export type AlertProps = AlertBaseProps;

const config: EditorConfigW<AlertProps> = {
  title: 'Önemli Uyarılar 1',
  description: 'Modern tasarımlı uyarı mesajı bloğu.',
  type: 'alert-1',
  code: 'AL1',
  category: ['alert'],
  suitable: ['header', 'main', 'footer'],
  styleDefault: {
    background: '#fff',
  },
  schema: {
    type: 'object',
    required: ['title', 'description', 'type'],
    properties: {
      title: {
        type: 'string',
        title: 'Başlık',
        default: 'Yeni Özellikler!',
      },
      description: {
        type: 'string',
        title: 'Açıklama',
        default: 'Yeni özelliklerimizi keşfedin ve işinizi daha verimli hale getirin.',
      },
      showButton: {
        type: 'boolean',
        title: 'Buton Göster',
        default: true,
      },
      button: {
        type: 'object',
        title: 'Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
      type: {
        type: 'string',
        title: 'Uyarı Tipi',
        enum: ['warning', 'error', 'success', 'info'],
        default: 'warning',
      },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      title: 'Yeni Özellikler!',
      description: 'Yeni özelliklerimizi keşfedin ve işinizi daha verimli hale getirin.',
      showButton: true,
      button: {
        text: 'Daha Fazla Bilgi',
        url: '#',
      },
      type: 'warning',
    };
  },
};

export default config;
