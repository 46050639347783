import { EditorConfigW } from '../../../../service/Type';

export interface PricingProps {
  title: string;
  description: string;
  plans: {
    name: string;
    price: string;
    interval: string;
    description: string;
    features: string[];
    buttonText: string;
    recommended?: boolean;
  }[];
}

const config: EditorConfigW<PricingProps> = {
  title: 'Fiyatlandırma Tablosu',
  description: 'Ürün veya hizmetlerinizin fiyatlandırma planlarını gösteren karşılaştırmalı tablo.',
  type: 'pricing-1',
  code: 'PR1',
  category: ['pricing'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'description', 'plans'],
    properties: {
      title: {
        type: 'string',
        title: 'Başlık',
        default: 'Simple, transparent pricing',
      },
      description: {
        type: 'string',
        title: 'Açıklama',
        default: 'Choose the perfect plan for your needs. Always flexible, always scalable.',
      },
      plans: {
        type: 'array',
        title: 'Planlar',
        items: {
          type: 'object',
          properties: {
            name: { type: 'string', title: 'Plan Adı' },
            price: { type: 'string', title: 'Fiyat' },
            interval: { type: 'string', title: 'Periyot' },
            description: { type: 'string', title: 'Plan Açıklaması' },
            features: {
              type: 'array',
              title: 'Özellikler',
              items: { type: 'string' },
            },
            buttonText: { type: 'string', title: 'Buton Metni' },
            recommended: { type: 'boolean', title: 'Önerilen Plan' },
          },
        },
      },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      title: 'Simple, transparent pricing',
      description: 'Choose the perfect plan for your needs. Always flexible, always scalable.',
      plans: [
        {
          name: 'Starter',
          price: '$29',
          interval: 'month',
          description: 'Perfect for small projects',
          features: ['Up to 5 projects', 'Basic analytics', '24/7 support', '1GB storage'],
          buttonText: 'Get Started',
        },
        {
          name: 'Professional',
          price: '$99',
          interval: 'month',
          description: 'Ideal for growing businesses',
          features: ['Unlimited projects', 'Advanced analytics', 'Priority support', '10GB storage', 'Custom domain'],
          buttonText: 'Start Pro',
          recommended: true,
        },
        {
          name: 'Enterprise',
          price: '$299',
          interval: 'month',
          description: 'For large scale operations',
          features: [
            'Unlimited everything',
            'White-label options',
            'Dedicated support',
            '100GB storage',
            'Advanced security',
            'Custom integrations',
          ],
          buttonText: 'Contact Us',
        },
      ],
    };
  },
};

export default config;
