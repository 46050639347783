import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header7Props {
  logo: {
    src: string;
    alt: string;
  };
  navigation: {
    text: string;
    url: string;
  }[];
  buttons: {
    text: string;
    url: string;
    primary?: boolean;
  }[];
}

const config: EditorConfigW<Header7Props> = {
  title: 'Header 7 - Butonlu',
  description: 'Navigasyon ve butonlar içeren header',
  type: 'header-7',
  code: 'HDR7',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#f8f9fa',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'navigation', 'buttons'],
    properties: {
      logo: {
        type: 'object',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      navigation: {
        type: 'array',
        title: 'Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
      buttons: {
        type: 'array',
        title: 'Butonlar',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Buton Metni' },
            url: { type: 'string', title: 'Buton URL' },
            primary: { type: 'boolean', title: 'Ana Buton mu?' },
          },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg', // Projeden logo al
      alt: project.title || 'Logo', // Proje başlığını alt metin olarak al
    },
    navigation: [
      { text: 'Hakkımızda', url: '#' },
      { text: 'Hizmetler', url: '#' },
      { text: 'İletişim', url: '#' },
    ],
    buttons: [
      { text: 'Giriş Yap', url: '#', primary: false },
      { text: 'Ücretsiz Başla', url: '#', primary: true },
    ],
  }),
};

export default config;
