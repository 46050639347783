import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header11Props {
  logo: {
    src: string;
    alt: string;
  };
  navigation: {
    text: string;
    url: string;
  }[];
}

const config: EditorConfigW<Header11Props> = {
  title: 'Header 11 - Sosyal Medya Odaklı',
  description: 'Sosyal medya ikonları ve menü içeren header',
  type: 'header-11',
  code: 'HDR11',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#ffffff',
    color: '#333333',
  },
  schema: {
    type: 'object',
    required: ['logo', 'navigation'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      navigation: {
        type: 'array',
        title: 'Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    navigation: [
      { text: 'Ana Sayfa', url: '#' },
      { text: 'Hakkımızda', url: '#' },
      { text: 'Hizmetler', url: '#' },
      { text: 'İletişim', url: '#' },
    ],
  }),
};

export default config;
