import { EditorConfigW } from '../../../../service/Type';

export interface HeaderProps {
  logo: {
    src: string;
    alt: string;
  };
  megaMenu: {
    title: string;
    items: {
      text: string;
      url: string;
      description?: string;
      icon?: string;
    }[];
  }[];
  actionButton: {
    text: string;
    url: string;
  };
  theme?: 'light' | 'dark';
}

const config: EditorConfigW<HeaderProps> = {
  title: 'Mega Menülü Header',
  description: 'Detaylı mega menü içeren kurumsal header',
  type: 'header-5',
  code: 'HD5',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#fff',
  },
  schema: {
    type: 'object',
    required: ['logo', 'megaMenu', 'actionButton'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Şirket Logo' }
        }
      },
      megaMenu: {
        type: 'array',
        title: 'Mega Menü',
        items: {
          type: 'object',
          properties: {
            title: { type: 'string', title: 'Menü Başlığı' },
            items: {
              type: 'array',
              title: 'Menü Öğeleri',
              items: {
                type: 'object',
                properties: {
                  text: { type: 'string', title: 'Öğe Metni' },
                  url: { type: 'string', title: 'Öğe URL' },
                  description: { type: 'string', title: 'Açıklama' },
                  icon: { type: 'string', title: 'İkon' }
                }
              }
            }
          }
        }
      },
      actionButton: {
        type: 'object',
        title: 'Aksiyon Butonu',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' }
        }
      },
      theme: {
        type: 'string',
        title: 'Tema',
        enum: ['light', 'dark'],
        default: 'light'
      }
    }
  },
  propsDefault: async () => {
    return {
      logo: {
        src: '/logo.svg',
        alt: 'Şirket Logo'
      },
      megaMenu: [
        {
          title: 'Ürünler',
          items: [
            {
              text: 'Analitik',
              url: '#',
              description: 'Gelişmiş veri analizi araçları',
              icon: 'chart'
            },
            {
              text: 'Güvenlik',
              url: '#',
              description: 'Kurumsal güvenlik çözümleri',
              icon: 'shield'
            }
          ]
        },
        {
          title: 'Çözümler',
          items: [
            {
              text: 'Kurumsal',
              url: '#',
              description: 'Büyük ölçekli işletmeler için',
              icon: 'building'
            },
            {
              text: 'KOBİ',
              url: '#',
              description: 'Küçük ve orta ölçekli işletmeler için',
              icon: 'store'
            }
          ]
        }
      ],
      actionButton: {
        text: 'Ücretsiz Dene',
        url: '#'
      },
      theme: 'light'
    };
  },
};

export default config;
