import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Footer2Props {
  logo: {
    src: string;
    alt: string;
  };
  description: string;
  links: {
    text: string;
    url: string;
  }[];
}

const config: EditorConfigW<Footer2Props> = {
  title: 'Footer 2 - İki Sütunlu',
  description: 'Logo, açıklama ve bağlantılar içeren iki sütunlu footer',
  type: 'footer-2',
  code: 'FTR2',
  category: ['footer'],
  suitable: ['footer'],
  styleDefault: {
    background: '#f8f9fa',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'description', 'links'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      description: { type: 'string', title: 'Açıklama', default: 'Şirket açıklaması buraya gelecek.' },
      links: {
        type: 'array',
        title: 'Bağlantılar',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Link Metni' },
            url: { type: 'string', title: 'Link URL' },
          },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    description: `${project.title || 'Şirket Adı'} hakkında kısa bir açıklama.`,
    links: [
      { text: 'Ana Sayfa', url: '#' },
      { text: 'Hakkımızda', url: '#' },
      { text: 'Hizmetler', url: '#' },
      { text: 'İletişim', url: '#' },
    ],
  }),
};

export default config;
