import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Footer5Props {
  logo: {
    src: string;
    alt: string;
  };
  columns: {
    title: string;
    links: {
      text: string;
      url: string;
    }[];
  }[];
  bottomText: string;
}

const config: EditorConfigW<Footer5Props> = {
  title: 'Footer 5 - Mega Footer',
  description: 'Birden fazla sütun, açıklama ve alt bilgi içeren çok katmanlı footer',
  type: 'footer-5',
  code: 'FTR5',
  category: ['footer'],
  suitable: ['footer'],
  styleDefault: {
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'columns', 'bottomText'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      columns: {
        type: 'array',
        title: 'Sütunlar',
        items: {
          type: 'object',
          properties: {
            title: { type: 'string', title: 'Başlık' },
            links: {
              type: 'array',
              title: 'Bağlantılar',
              items: {
                type: 'object',
                properties: {
                  text: { type: 'string', title: 'Link Metni' },
                  url: { type: 'string', title: 'Link URL' },
                },
              },
            },
          },
        },
      },
      bottomText: { type: 'string', title: 'Alt Bilgi', default: '© 2024 Şirket Adı. Tüm Hakları Saklıdır.' },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    columns: [
      {
        title: 'Hakkımızda',
        links: [
          { text: 'Vizyon', url: '#' },
          { text: 'Misyon', url: '#' },
          { text: 'İletişim', url: '#' },
        ],
      },
      {
        title: 'Hizmetler',
        links: [
          { text: 'E-ticaret', url: '#' },
          { text: 'Danışmanlık', url: '#' },
          { text: 'Destek', url: '#' },
        ],
      },
      {
        title: 'Kaynaklar',
        links: [
          { text: 'SSS', url: '#' },
          { text: 'Blog', url: '#' },
          { text: 'Kariyer', url: '#' },
        ],
      },
    ],
    bottomText: `© ${new Date().getFullYear()} ${project.title || 'Şirket Adı'}. Tüm Hakları Saklıdır.`,
  }),
};

export default config;
