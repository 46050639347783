import { EditorConfigW } from '../../../../service/Type';

export interface HeaderProps {
  logo: {
    src: string;
    alt: string;
  };
  categories: {
    text: string;
    url: string;
    subCategories?: {
      text: string;
      url: string;
    }[];
  }[];
  searchPlaceholder: string;
  userMenu: {
    text: string;
    url: string;
    icon?: string;
  }[];
  theme?: 'light' | 'dark';
}

const config: EditorConfigW<HeaderProps> = {
  title: 'E-ticaret Header',
  description: 'Arama ve kategori menülü e-ticaret header',
  type: 'header-6',
  code: 'HD6',
  category: ['header'],
  suitable: ['header'],
  schema: {
    type: 'object',
    required: ['logo', 'categories', 'searchPlaceholder', 'userMenu'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL' },
          alt: { type: 'string', title: 'Logo Alt Metni' }
        }
      },
      categories: {
        type: 'array',
        title: 'Kategoriler',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Kategori Metni' },
            url: { type: 'string', title: 'Kategori URL' },
            subCategories: {
              type: 'array',
              title: 'Alt Kategoriler',
              items: {
                type: 'object',
                properties: {
                  text: { type: 'string', title: 'Alt Kategori Metni' },
                  url: { type: 'string', title: 'Alt Kategori URL' }
                }
              }
            }
          }
        }
      },
      searchPlaceholder: { type: 'string', title: 'Arama Kutusu Metni' },
      userMenu: {
        type: 'array',
        title: 'Kullanıcı Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
            icon: { type: 'string', title: 'Menü İkonu' }
          }
        }
      },
      theme: {
        type: 'string',
        title: 'Tema',
        enum: ['light', 'dark'],
        default: 'light'
      }
    }
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      logo: {
        src: '/logo.svg',
        alt: 'Şirket Logo'
      },
      categories: [
        {
          text: 'Kategori 1',
          url: '#',
          subCategories: [
            {
              text: 'Alt Kategori 1.1',
              url: '#'
            },
            {
              text: 'Alt Kategori 1.2',
              url: '#'
            }
          ]
        },
        {
          text: 'Kategori 2',
          url: '#',
          subCategories: [
            {
              text: 'Alt Kategori 2.1',
              url: '#'
            },
            {
              text: 'Alt Kategori 2.2',
              url: '#'
            }
          ]
        }
      ],
      searchPlaceholder: 'Arama...',
      userMenu: [
        {
          text: 'Profil',
          url: '#'
        },
        {
          text: 'Çıkış',
          url: '#'
        }
      ],
      theme: 'light'
    };
  },
};

export default config;
