import { EditorConfigW } from '../../../../service/Type';

export interface HeaderProps {
  logo: {
    src: string;
    alt: string;
  };
  navigation: {
    text: string;
    url: string;
  }[];
  buttons: {
    text: string;
    url: string;
    primary?: boolean;
  }[];
  showButtons?: boolean;
}

const config: EditorConfigW<HeaderProps> = {
  title: 'Modern Header',
  description: 'Navigasyon ve çift buton içeren modern header',
  type: 'header-3',
  code: 'HDR3',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#000',
  },
  schema: {
    type: 'object',
    required: ['logo', 'navigation'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Şirket Logo' },
        },
      },
      navigation: {
        type: 'array',
        title: 'Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
      showButtons: {
        type: 'boolean',
        title: 'Butonları Göster',
        default: true,
      },
      buttons: {
        type: 'array',
        title: 'Butonlar',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Buton Metni' },
            url: { type: 'string', title: 'Buton URL' },
            primary: { type: 'boolean', title: 'Ana Buton mu?' },
          },
        },
      },
    },
  },
  propsDefault: async () => {
    return {
      logo: {
        src: '/logo.svg',
        alt: 'Şirket Logo',
      },
      navigation: [
        { text: 'Ürünler', url: '#' },
        { text: 'Özellikler', url: '#' },
        { text: 'Fiyatlandırma', url: '#' },
        { text: 'Hakkımızda', url: '#' },
      ],
      showButtons: true,
      buttons: [
        { text: 'Giriş Yap', url: '#', primary: false },
        { text: 'Ücretsiz Başla', url: '#', primary: true },
      ],
    };
  },
};

export default config;
