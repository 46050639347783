import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta() {
  return (
    <Helmet defaultTitle="PixelPerfect" titleTemplate="%s | PixelPerfect">
      <meta charSet="utf-8" />
      <base href="/" target="_blank" />
      <meta name="copyright" content="Ahmet Samet Yüzlü" />
      <meta name="robots" content="index,follow" />
      <meta property="og:locale" content="tr" />
      <meta property="og:site_name" content="PixelPerfect" />
    </Helmet>
  );
}
