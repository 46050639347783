import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from './component/Layout';
import LayoutProject from './component/LayoutProject';
import LayoutProjectCreate from './component/LayoutProjectCreate';

import Dashboard from './module/Dashboard';
import Pricing from './module/Pricing';
import Help from './module/Help';
import NotFound from './module/NotFound';
import Website from './module/website/Website';
import WebsiteSetting from './module/project-setting/WebsiteSetting';
import WebsitePageCreate from './module/project-page/WebsitePageCreate';
import WebsitePageEditor from './module/project-page/WebsitePageEditor';
import WebsiteCreate from './module/project-create/WebsiteCreate';
import Content from './module/content/Content';

import './App.scss';

export default function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/fiyatlar/*" element={<Pricing />} />
        <Route path="/yardim/*" element={<Help />} />
        <Route path="/kullanim-sartlari" element={<Content />} />
        <Route path="/gizlilik-politikasi" element={<Content />} />
        <Route path="/cerez-politikasi" element={<Content />} />
        <Route path="/iletisim" element={<Content />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<LayoutProject />}>
        <Route path="/project/:projectId" element={<Website />} />
        <Route path="/project/:projectId/ayarlar/*" element={<WebsiteSetting />} />
        <Route path="/project/:projectId/sayfa-olustur" element={<WebsitePageCreate />} />
      </Route>
      <Route element={<LayoutProjectCreate />}>
        <Route path="/project-olustur" element={<WebsiteCreate />} />
      </Route>
      <Route element={<LayoutProjectCreate />}>
        <Route path="/project/:projectId/sayfa/:pageId/duzenle" element={<WebsitePageEditor />} />
      </Route>
    </Routes>
  );
}
