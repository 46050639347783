import { EditorConfigW } from '../../../../service/Type';

export interface HeroProps {
  title: string;
  description: string;
  stats: {
    value: string;
    label: string;
  }[];
  primaryButton: {
    text: string;
    url: string;
  };
}

const config: EditorConfigW<HeroProps> = {
  title: 'Hero Bileşeni 2',
  description: 'İstatistikler ve tek buton içeren modern hero bileşeni',
  type: 'hero-2',
  code: 'HR2',
  category: ['hero'],
  suitable: ['header', 'main'],
  styleDefault: {
    background: '#fff',
  },
  schema: {
    type: 'object',
    required: ['title', 'description', 'stats', 'primaryButton'],
    properties: {
      title: {
        type: 'string',
        title: 'Başlık',
        default: 'Verilerle Desteklenen Dijital Çözümler',
      },
      description: {
        type: 'string',
        title: 'Açıklama',
        default:
          'Müşterilerimize sunduğumuz yenilikçi çözümlerle dijital dönüşüm yolculuğunuzda yanınızdayız. Kanıtlanmış başarı oranlarımızla işinizi büyütün.',
      },
      stats: {
        type: 'array',
        title: 'İstatistikler',
        items: {
          type: 'object',
          properties: {
            value: { type: 'string', title: 'Değer' },
            label: { type: 'string', title: 'Etiket' },
          },
        },
      },
      primaryButton: {
        type: 'object',
        title: 'Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      title: 'Verilerle Desteklenen Dijital Çözümler',
      description:
        'Müşterilerimize sunduğumuz yenilikçi çözümlerle dijital dönüşüm yolculuğunuzda yanınızdayız. Kanıtlanmış başarı oranlarımızla işinizi büyütün.',
      stats: [
        { value: '500+', label: 'Mutlu Müşteri' },
        { value: '%98', label: 'Müşteri Memnuniyeti' },
        { value: '24/7', label: 'Destek' },
      ],
      primaryButton: {
        text: 'Hemen Başlayın',
        url: '#',
      },
    };
  },
};

export default config;
