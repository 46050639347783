import React from 'react';
import { ViewerPropsT } from '../../../../service/Type';
import { EProps } from './config';

export default ({ props, project }: ViewerPropsT<EProps>) => {
  return (
    <div className="py-24">
      <h1 className="mb-4 text-4xl title-font font-extrabold leading-none tracking-tight  dark:text-white text-center">{props.title}</h1>
      <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 text-center">
        {props.description}
      </p>
      <div className="text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 text-center">
        <a
          href={props.link}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          style={{ backgroundColor: project.colorPrimary }}
        >
          {props.text}
        </a>
      </div>
    </div>
  );
};
