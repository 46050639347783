import { EditorConfigW } from '../../../../service/Type';

export interface HeroProps {
  title: string;
  description: string;
  primaryButton: {
    text: string;
    url: string;
  };
  secondaryButton: {
    text: string;
    url: string;
  };
}

const config: EditorConfigW<HeroProps> = {
  title: 'Ana Sayfa Hero',
  description: 'İki butonlu, modern hero bileşeni',
  type: 'hero-1',
  code: 'HR1',
  category: ['hero'],
  suitable: ['header', 'main'],
  styleDefault: {
    background: '#fff',
  },
  schema: {
    type: 'object',
    required: ['title', 'description', 'primaryButton', 'secondaryButton'],
    properties: {
      title: {
        type: 'string',
        title: 'Başlık',
        default: 'Dijital Dünyada Öne Çıkın',
      },
      description: {
        type: 'string',
        title: 'Açıklama',
        default:
          'Modern ve etkileyici web sitesi çözümleriyle işletmenizi bir adım öne taşıyın. Profesyonel, hızlı ve kullanıcı dostu tasarımlar.',
      },
      primaryButton: {
        type: 'object',
        title: 'Ana Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
      secondaryButton: {
        type: 'object',
        title: 'İkincil Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      title: 'Dijital Dünyada Öne Çıkın',
      description:
        'Modern ve etkileyici web sitesi çözümleriyle işletmenizi bir adım öne taşıyın. Profesyonel, hızlı ve kullanıcı dostu tasarımlar.',
      primaryButton: {
        text: 'Hemen Başlayın',
        url: '#',
      },
      secondaryButton: {
        text: 'Daha Fazla Bilgi',
        url: '#',
      },
    };
  },
};

export default config;
