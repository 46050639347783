import React, { useEffect, useState } from 'react';
import { Badge, Drawer, Pagination } from 'antd';
import { Category, EditorCreateModalProps } from '../../service/Type';
import { blockList } from './component/Data';
import EditorBlockCreateAsyncBlock from './EditorBlockCreateAsyncBlock';
import EditorThemeConfigProvider from './EditorThemeConfigProvider';
import './EditorBlockCreate.scss';
import { ThemeProvider } from './ThemeProvider';

export const categoryList: Category[] = [
  { categoryId: 'cover', title: 'Giriş' },
  { categoryId: 'alert', title: 'Uyarılar' },
  { categoryId: 'about', title: 'Hakkında' },
  { categoryId: 'title', title: 'Başlık' },
  { categoryId: 'content', title: 'İçerik' },
  { categoryId: 'slider', title: 'Slider' },
  { categoryId: 'image', title: 'Resim' },
  { categoryId: 'gallery', title: 'Galeri' },
  { categoryId: 'feature', title: 'Özellikler' },
  { categoryId: 'menu', title: 'Menü' },
  { categoryId: 'store', title: 'Mağaza' },
  { categoryId: 'divider', title: 'Ayraç' },
  { categoryId: 'index', title: 'Index' },
  { categoryId: 'header', title: 'Header' },
  { categoryId: 'header-social', title: 'Header Social' },
  { categoryId: 'footer', title: 'Footer' },
  { categoryId: 'video', title: 'Video' },
  { categoryId: 'team', title: 'Ekip' },
  { categoryId: 'reviews', title: 'Reviews' },
  { categoryId: 'timetable', title: 'Timetable' },
  { categoryId: 'how-it-work', title: 'Nasıl Çalışıyor?' },
  { categoryId: 'contact', title: 'İletişim' },
  { categoryId: 'service', title: 'Hizmetler' },
  { categoryId: 'product', title: 'Ürünler' },
  { categoryId: 'product-detail', title: 'Ürün Detay' },
  { categoryId: 'project', title: 'Projeler' },
  { categoryId: 'social-media', title: 'Sosyal Medya' },
  { categoryId: 'pricing', title: 'Ücretlendirme' },
  { categoryId: 'statistic', title: 'İstatistik' },
  { categoryId: 'partner', title: 'Ortaklar' },
  { categoryId: 'testimonial', title: 'Testimonial' },
  { categoryId: 'blog', title: 'Blog' },
  { categoryId: 'blog-detail', title: 'Blog Detay' },
  { categoryId: 'error', title: 'Hata' },
  { categoryId: 'logo-cloud', title: 'Logo Cloud' },
  { categoryId: 'process', title: 'Süreç' },
  { categoryId: 'story', title: 'Hikaye' },
  { categoryId: 'hero', title: 'Hero' },
  { categoryId: '3d', title: '3D' },
];

export default function EditorBlockCreate({ open, onCancel, onCreate, page, project, part }: EditorCreateModalProps) {
  const [filterCategoryId, setFilterCategoryId] = useState<string>();
  const [current, setCurrent] = useState(1);

  // const blocks = blockList.map(block => block.config.title);
  // console.log(blocks.join("\n"));

  useEffect(() => {
    setCurrent(1);
  }, [filterCategoryId]);

  let blockListFiltered = [...blockList];
  if (part) {
    blockListFiltered = blockListFiltered.filter((block) => block.config.suitable.includes(part));
  }

  let blockListSearched = [...blockList];
  if (filterCategoryId) {
    blockListSearched = blockListSearched.filter((block) => block.config.category.includes(filterCategoryId));
  }
  if (part) {
    blockListSearched = blockListSearched.filter((block) => block.config.suitable.includes(part));
  }

  categoryList
    .sort((a, b) => a.title.localeCompare(b.title))
    .forEach((category) => {
      category.blockList = [
        ...blockListFiltered.filter((block) => block.config.category.includes(category.categoryId)).map((block) => block.config.type),
      ];
    });

  return (
    <Drawer title="Blok Seç" open={open} onClose={onCancel} width="80%" placement="left">
      <ThemeProvider projectId={project.projectId} themeDefault="light">
        <div className="editor-block-create">
          <div className="block-create-category">
            <div onClick={() => setFilterCategoryId('')} className={!filterCategoryId ? 'block-create-category-active' : undefined}>
              Tümü
              <Badge count={blockListFiltered.length || 0} showZero size="small" />
            </div>
            {categoryList
              .filter((category) => category.blockList && category.blockList?.length > 0)
              .map((category) => (
                <div
                  key={category.categoryId}
                  onClick={() => setFilterCategoryId(category.categoryId)}
                  className={category.categoryId === filterCategoryId ? 'block-create-category-active' : undefined}
                >
                  {category.title}
                  <Badge count={category.blockList?.length || 0} showZero size="small" />
                </div>
              ))}
          </div>
          <div className="block-create-component">
            <EditorThemeConfigProvider project={project}>
              <React.Suspense fallback={'Yükleniyor...'}>
                {blockListSearched.slice((current - 1) * 10, current * 10).map((block) => (
                  <EditorBlockCreateAsyncBlock key={block.config.type} block={block} project={project} page={page} onCreate={onCreate} />
                ))}
              </React.Suspense>
            </EditorThemeConfigProvider>
          </div>
          <div className="block-create-pagination">
            <Pagination current={current} total={blockListSearched.length} onChange={(current) => setCurrent(current)} />
          </div>
        </div>
      </ThemeProvider>
    </Drawer>
  );
}
