import * as React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { DollarOutlined, QuestionOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import keycloak from '../service/Keycloak';
import './Layout.scss';

export default function Layout() {
  const location = useLocation();

  const menu = [
    {
      key: '/fiyatlar',
      label: 'Fiyatlar',
      icon: <DollarOutlined />,
    },
    {
      key: '/yardim',
      label: 'Yardım',
      icon: <QuestionOutlined />,
    },
  ];

  return (
    <>
      <header>
        <Link to="/" className="logo">
          <img src="/img/logo/logo-white.svg" alt="PixelPerfect" />
        </Link>
        <ul className="menu">
          {menu.map((item, itemKey) => (
            <li key={itemKey} className={location.pathname == item.key ? 'active' : ''}>
              <Link to={item.key}>{item.label}</Link>
            </li>
          ))}
        </ul>
        <Button onClick={() => keycloak.logout()} icon={<LogoutOutlined />}>
          {keycloak?.profile?.firstName} {keycloak?.profile?.lastName}
        </Button>
      </header>
      <main>
        <Outlet />
      </main>
      <footer style={{ textAlign: 'center', background: '#202020', color: '#fff' }}>
        <p>© PixelPerfect 2025</p>
        <p>
          Herhangi bir sorunuzda lütfen bize yazın
          <span> </span>
          <a href="mailto:yardim@pixelperfect.tr">yardim@pixelperfect.tr</a>
        </p>
        <p>
          <Link to="/kullanim-sartlari">Kullanım Şartları</Link>
          <span> ve </span>
          <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
        </p>
      </footer>
    </>
  );
}
