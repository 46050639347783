import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Footer3Props {
  logo: {
    src: string;
    alt: string;
  };
  description: string;
  links1: {
    text: string;
    url: string;
  }[];
  links2: {
    text: string;
    url: string;
  }[];
  contactInfo: {
    email: string;
    phone: string;
  };
}

const config: EditorConfigW<Footer3Props> = {
  title: 'Footer 3 - Üç Sütunlu',
  description: 'Logo, bağlantılar ve iletişim bilgileri içeren üç sütunlu footer',
  type: 'footer-3',
  code: 'FTR3',
  category: ['footer'],
  suitable: ['footer'],
  styleDefault: {
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'description', 'links1', 'links2', 'contactInfo'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      description: { type: 'string', title: 'Açıklama', default: 'Şirket açıklaması buraya gelecek.' },
      links1: {
        type: 'array',
        title: 'Bağlantılar (Sütun 1)',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Link Metni' },
            url: { type: 'string', title: 'Link URL' },
          },
        },
      },
      links2: {
        type: 'array',
        title: 'Bağlantılar (Sütun 2)',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Link Metni' },
            url: { type: 'string', title: 'Link URL' },
          },
        },
      },
      contactInfo: {
        type: 'object',
        title: 'İletişim Bilgileri',
        properties: {
          email: { type: 'string', title: 'E-posta', default: 'info@example.com' },
          phone: { type: 'string', title: 'Telefon', default: '+90 312 123 45 67' },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    description: `${project.title || 'Şirket Adı'} hakkında kısa bir açıklama.`,
    links1: [
      { text: 'Ana Sayfa', url: '#' },
      { text: 'Hakkımızda', url: '#' },
      { text: 'Hizmetler', url: '#' },
    ],
    links2: [
      { text: 'İletişim', url: '#' },
      { text: 'Destek', url: '#' },
      { text: 'SSS', url: '#' },
    ],
    contactInfo: {
      email: project.contactEmail || 'info@example.com',
      phone: project.contactPhone || '+90 312 123 45 67',
    },
  }),
};

export default config;
