import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header13Props {
  logo: {
    src: string;
    alt: string;
  };
  heading: string;
  subheading: string;
  buttons: {
    text: string;
    url: string;
    primary?: boolean;
  }[];
  backgroundImage: string;
}

const config: EditorConfigW<Header13Props> = {
  title: 'Hero Bileşeni 3',
  description: 'Büyük başlık, alt başlık ve butonlar içeren hero header',
  type: 'hero-3',
  code: 'HR3',
  category: ['header'],
  suitable: ['hero', 'header'],
  styleDefault: {
    background: '#f8f9fa',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'heading', 'subheading', 'buttons', 'backgroundImage'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      heading: { type: 'string', title: 'Başlık', default: 'Hoş Geldiniz!' },
      subheading: { type: 'string', title: 'Alt Başlık', default: 'Sizin için en iyi çözümleri sunuyoruz.' },
      buttons: {
        type: 'array',
        title: 'Butonlar',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Buton Metni' },
            url: { type: 'string', title: 'Buton URL' },
            primary: { type: 'boolean', title: 'Ana Buton mu?' },
          },
        },
      },
      backgroundImage: { type: 'string', title: 'Arka Plan Görseli URL', default: '/background.jpg' },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    heading: `${project.title || 'Projeniz'} ile Tanışın`,
    subheading: project.slogan || 'İhtiyacınız olan her şey burada!',
    buttons: [
      { text: 'Hemen Başlayın', url: '#', primary: true },
      { text: 'Daha Fazlasını Keşfedin', url: '#' },
    ],
    backgroundImage: '/hero-bg.jpg',
  }),
};

export default config;
