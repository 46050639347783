import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Footer6Props {
  logo: {
    src: string;
    alt: string;
  };
  address: string;
  phone: string;
  email: string;
  workingHours: string;
}

const config: EditorConfigW<Footer6Props> = {
  title: 'Footer 6 - İletişim Odaklı',
  description: 'Adres, telefon, e-posta ve çalışma saatleri içeren iletişim odaklı footer',
  type: 'footer-6',
  code: 'FTR6',
  category: ['footer'],
  suitable: ['footer'],
  styleDefault: {
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'address', 'phone', 'email', 'workingHours'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      address: { type: 'string', title: 'Adres', default: 'Adres bilgisi buraya gelecek.' },
      phone: { type: 'string', title: 'Telefon', default: '+90 312 123 45 67' },
      email: { type: 'string', title: 'E-posta', default: 'info@example.com' },
      workingHours: { type: 'string', title: 'Çalışma Saatleri', default: 'Pzt - Cum: 09:00 - 18:00' },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg',
      alt: project.title || 'Logo',
    },
    address: project.contactAddress || 'Adres bilgisi buraya gelecek.',
    phone: project.contactPhone || '+90 312 123 45 67',
    email: project.contactEmail || 'info@example.com',
    workingHours: 'Pzt - Cum: 09:00 - 18:00',
  }),
};

export default config;
