import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Footer1Props {
  text: string;
}

const config: EditorConfigW<Footer1Props> = {
  title: 'Footer 1 - Minimalist',
  description: 'Telif hakkı ve kısa açıklama içeren minimalist footer',
  type: 'footer-1',
  code: 'FTR1',
  category: ['footer'],
  suitable: ['footer'],
  styleDefault: {
    background: '#f8f9fa',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['text'],
    properties: {
      text: { type: 'string', title: 'Alt Bilgi Metni', default: '© 2024 Şirket Adı. Tüm Hakları Saklıdır.' },
    },
  },
  propsDefault: async (project: IProject) => ({
    text: `© ${new Date().getFullYear()} ${project.title || 'Şirket Adı'}. Tüm Hakları Saklıdır.`,
  }),
};

export default config;
