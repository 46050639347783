import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ConfigProvider, theme as themeAntd } from 'antd';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import keycloak from './service/Keycloak';
import App from './App';
import './service/I18n.ts';
import './index.scss';

import trTr from 'antd/locale/tr_TR';
import { loadInitial } from './service/api/Initial';
import { store } from './service/store/store';

import Meta from './component/Meta';

async function init() {
  // try {
    const authenticated = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      enableLogging: true,
      responseMode: 'query',
      checkLoginIframeInterval: 60,
    });

    if (!authenticated) {
      await keycloak.login();
    }

    setInterval(() => {
      keycloak
        .updateToken(180)
        .then(function(refreshed) {
          if (refreshed) {
            console.log('Token was successfully refreshed');
            localStorage.setItem('token', keycloak.token as string);
          } else {
            console.log('Token is still valid');
          }
        })
        .catch(function() {
          console.log('Failed to refresh the token, or the session has expired');
        });
    }, 60 * 1000);

    localStorage.setItem('token', keycloak.token as string);

    await keycloak.loadUserProfile();
    await loadInitial();

    const root = ReactDOM.createRoot(document.getElementById('builder') as HTMLElement);
    const { defaultAlgorithm } = themeAntd;

    root.render(
      <>
        <BrowserRouter>
          <ConfigProvider
            locale={trTr}
            theme={{
              algorithm: defaultAlgorithm,
              token: {
                colorPrimary: '#106fff',
                fontFamily: "'Inter', sans-serif;",
              },
              hashed: false,
            }}
          >
            <Provider store={store}>
              <App />
            </Provider>
          </ConfigProvider>
          <Meta />
        </BrowserRouter>
      </>,
    );
  // } catch (e) {
  //   console.error(e);
  // } finally {
  //   // Do nothing for now
  // }
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
