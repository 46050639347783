import React from 'react';

export default function Contact() {
  return (
    <>
      <h1>İletişim</h1>
      <p>Son güncellenme: 01/10/2025</p>
      <p>
        Herhangi bir sorunuzda lütfen bize yazın
        <span> </span>
        <a href="mailto:yardim@pixelperfect.tr">yardim@pixelperfect.tr</a>
      </p>
    </>
  );
}
