import React from 'react';
import Breadcrumb from '../component/Breadcrumb';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd';

export default function Help() {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: '1. Giriş',
      children: (
        <>
          Hoş geldiniz! Bu platform, farklı kişiler ve personalar için hızlı ve etkili bir şekilde landing page oluşturmanıza olanak sağlar.
          Bu yardım kılavuzu, platformu nasıl kullanacağınızı adım adım açıklamaktadır.
        </>
      ),
    },
    {
      key: '2',
      label: '2. Başlarken',
      children: (
        <>
          Kayıt ve Giriş Ana sayfadan &quot;Kayıt Ol&quot; butonuna tıklayın. Bilgilerinizi doldurarak hesap oluşturun. Mevcut bir hesabınız
          varsa, &quot;Giriş Yap&quot; butonuna tıklayın. Profil Ayarları Hesap ayarlarınıza giderek adınızı, profil fotoğrafınızı ve diğer
          bilgilerinizi güncelleyebilirsiniz.
        </>
      ),
    },
    {
      key: '3',
      label: '3. Yeni Bir Proje Oluşturma',
      children: (
        <>
          Proje Başlatma Ana kontrol panelinden &quot;Yeni Proje&quot; butonuna tıklayın. Projenize bir ad verin ve hedef kitlenizi seçin.
          Şablon Seçme Önceden hazırlanmış şablonlardan birini seçin veya boş bir sayfa ile başlayın. Bölüm Ekleyin Sürükle-bırak aracı ile
          başlıklar, metin blokları, görseller, formlar ve düğmelere ekleyin.
        </>
      ),
    },
    {
      key: '4',
      label: '4. Yayınlama',
      children: (
        <>
          Önizleme Sayfanızın önizlemesini kontrol edin ve mobil/masaüstü görünülerini test edin. Yayınla &quot;Yayınla&quot; butonuna
          tıklayarak sayfanızı yayına alın. Özel alan adı bağlama veya varsayılan alan adınızı kullanma seçeneğiniz bulunmaktadır.
        </>
      ),
    },
    {
      key: '5',
      label: '5. Sık Sorulan Sorular (SSS)',
      children: (
        <>
          1: Şablonlarımı değiştirebilir miyim? Evet, projeyi düzenlerken şablonları dilediğiniz zaman değiştirebilirsiniz.<br />
          2: Sayfam mobil uyumlu mu? Evet, tüm şablonlarımız tamamen mobil uyumludur. <br />
          3: Teknik destek nasıl alabilirim? Yardım merkezimizden bize ulaşabilirsiniz veya destek formunu doldurabilirsiniz.
          <br />
        </>
      ),
    },
    {
      key: '6',
      label: '6. İletişim',
      children: (
        <>
          Herhangi bir sorunuz veya öneriniz varsa, lütfen destek ekibimizle iletişime geçin: <br />
          E-posta: destek@pixelperfect.tr <br />
          Telefon: +90 506 345 3459 <br />
          Teşekkür ederiz ve başarılar dileriz!
        </>
      ),
    },
  ];
  return (
    <div className="page">
      <Breadcrumb
        items={[
          {
            title: 'Yardım',
          },
        ]}
      />

      <div className="page-title">
        <h1>Yardım</h1>
        <span style={{ flex: 1 }} />
      </div>

      <Collapse items={items} defaultActiveKey={['1']} />
    </div>
  );
}
