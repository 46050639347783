import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Contact1Props {
  address: string;
  phone: string;
  email: string;
  lat: number;
  lng: number;
}

const config: EditorConfigW<Contact1Props> = {
  title: 'Contact 1 - Tek Sütunlu İletişim',
  description: 'Adres, telefon, e-posta ve harita içeren tek sütunlu iletişim modülü.',
  type: 'contact-1',
  code: 'CNT1',
  category: ['contact'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['address', 'phone', 'email', 'lat', 'lng'],
    properties: {
      address: { type: 'string', title: 'Adres', default: '' },
      phone: { type: 'string', title: 'Telefon', default: '+90 312 123 45 67' },
      email: { type: 'string', title: 'E-posta', default: 'info@example.com' },
      lat: { type: 'number', title: 'Enlem (Lat)', default: 39.9208 },
      lng: { type: 'number', title: 'Boylam (Lng)', default: 32.8541 },
    },
  },
  propsDefault: async (project: IProject) => ({
    address: project.contactAddress || 'Ankara, Türkiye',
    phone: project.contactPhone || '+90 312 123 45 67',
    email: project.contactEmail || 'info@example.com',
    lat: 39.9208,
    lng: 32.8541,
  }),
};

export default config;
