import React from 'react';
import { Button, Modal } from 'antd';
import { EditorPreviewProps } from '../../service/Type';
import './EditorPreview.scss';
import { blockList } from './component/Data';
import Browser from '../Browser';
import EditorThemeConfigProvider from './EditorThemeConfigProvider';
import ComponentStyle from './component-shared/ComponentStyle';
import { ThemeProvider } from './ThemeProvider';

export default function EditorPreview({ open, onCancel, project, page, pageBlockList }: EditorPreviewProps) {
  // const header = headerList.find(
  //   (block) => block.blockId === project.header.blockId,
  // );
  //
  // const footer = footerList.find(
  //   (block) => block.blockId === project.footer.blockId,
  // );

  const parts = [
    {
      value: 'header',
      label: 'Header',
    },
    {
      value: 'main',
      label: 'Main',
    },
    {
      value: 'footer',
      label: 'Footer',
    },
  ];

  return (
    <Modal
      title="Önizleme"
      open={open}
      onCancel={onCancel}
      cancelText="Kapat"
      width="95%"
      bodyStyle={{ height: '80vh' }}
      centered
      footer={[
        <Button key="back" onClick={onCancel}>
          Kapat
        </Button>,
      ]}
    >
      <div className="editor-preview">
        <Browser link={`${project?.linkSchema}${project?.link}${page?.path}`} title={page.title}>
          <ThemeProvider projectId={project.projectId} themeDefault="light">
            <React.Suspense fallback={'Yükleniyor...'}>
              {parts.map((part) => {
                const pageBlocksPart = pageBlockList.filter((pageBlock) => pageBlock.part === part.value).sort((a, b) => a.sort - b.sort);

                return (
                  <React.Fragment key={part.value}>
                    {pageBlocksPart
                      .sort((a, b) => a.sort - b.sort)
                      .map((pageBlock, blockIndex) => {
                        const block = blockList.find((block) => block.config?.type === pageBlock.block?.type);

                        if (!block) {
                          return null;
                        }

                        return (
                          <div key={pageBlock.blockId}>
                            <EditorThemeConfigProvider project={project} isEven={blockIndex % 2 === 0}>
                              <ComponentStyle style={pageBlock.block.style}>
                                <block.viewer
                                  blockId={pageBlock.blockId}
                                  project={project}
                                  page={page}
                                  style={pageBlock.block.style}
                                  props={pageBlock.block.props}
                                />
                              </ComponentStyle>
                            </EditorThemeConfigProvider>
                          </div>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </React.Suspense>
          </ThemeProvider>
        </Browser>
      </div>
    </Modal>
  );
}
