import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Card, Modal, notification, Tooltip } from "antd";
import type { IError, IContentProduct, IProject } from '../../service/Type';
import { loadContentProductList, contentProductDelete } from '../../service/api/ContentProduct';
import ImageScroll from '../../component/ImageScroll';
import ProductEdit from './component/ProductEdit';
import ProductCreate from './component/ProductCreate';
import { ThemeProvider } from '../../component/editor/ThemeProvider';
import './WebsiteProduct.scss';

interface WebsiteProductProps {
  project: IProject;
}

export default function WebsiteProduct({ project }: WebsiteProductProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [contentProductList, setProductList] = useState<IContentProduct[]>([]);

  const [contentProductId, setContentProductId] = useState<string>();
  const [contentCreate, setContentCreate] = useState<boolean>();

  const load = async () => {
    const contentProductListResult = await loadContentProductList({
      filter: {
        projectId: project.projectId,
      },
    });
    if (contentProductListResult.result) {
      await setProductList(contentProductListResult.result);
    }
    if (contentProductListResult.errors) {
      await setError(contentProductListResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, [project.projectId]);

  if (loading) {
    return <div className="product">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="product">Hata</div>;
  }

  if (!project) {
    return <div className="product">Hata</div>;
  }

  return (
    <ThemeProvider projectId={project.projectId as string} themeDefault="light">
      <Button onClick={() => setContentCreate(true)} type="primary" style={{ marginBottom: 16 }}>
        Yeni Ürün Oluştur
      </Button>
      <div className="product-list">
        {contentProductList?.map((product) => (
          <Card
            className=""
            key={product.contentProductId}
            actions={[
              <Tooltip key="edit" title="Ürünü Düzenle">
                <div onClick={() => setContentProductId(product.contentProductId)}>
                  <EditOutlined />
                </div>
              </Tooltip>,
              <Tooltip key="edit" title="Ürünü Görüntüle">
                <a href={`${project.linkSchema}${project.link}/urun/${product.slug}`} target="_blank" rel="noreferrer">
                  <LinkOutlined />
                </a>
              </Tooltip>,
              <Tooltip key="delete" title="Ürünü Sil">
                <a
                  onClick={() => {
                    Modal.confirm({
                      title: 'Ürünü silmek istediğinizden emin misiniz?',
                      content: 'Ürün silindiğinde geri alınamaz.',
                      async onOk() {
                        const project = await contentProductDelete(product.contentProductId);
                        if (project.result) {
                          notification.success({
                            message: 'Başarı',
                            description: 'Ürün başarıyla silindi! ',
                          });
                          await load();
                        }
                      },
                    });
                  }}
                >
                  <DeleteOutlined type="link" />
                </a>
              </Tooltip>,
            ]}
            cover={<ImageScroll src={product.seoImage || product.imageList[0].image.path} />}
          >
            <div>
              <h2 style={{ margin: '0 0 16px ' }}>{product.title}</h2>
              <p>{product.seoTitle}</p>
              <p>{product.seoDescription}</p>
            </div>
          </Card>
        ))}
      </div>
      {contentProductId && (
        <ProductEdit
          contentProductId={contentProductId}
          onCancel={() => setContentProductId(undefined)}
          onUpdate={async () => {
            await load();
            setContentProductId(undefined);
          }}
        />
      )}
      {contentCreate && (
        <ProductCreate
          projectId={project.projectId}
          onCancel={() => setContentCreate(undefined)}
          onUpdate={async () => {
            await load();
            setContentCreate(undefined);
          }}
        />
      )}
    </ThemeProvider>
  );
}
