import { EditorConfigW, IProject } from '../../../../service/Type';

export interface Header10Props {
  logo: {
    src: string;
    alt: string;
  };
  leftNavigation: {
    text: string;
    url: string;
  }[];
  rightNavigation: {
    text: string;
    url: string;
  }[];
}

const config: EditorConfigW<Header10Props> = {
  title: 'Header 10 - Merkezi Logo',
  description: 'Merkezi logo ve iki tarafa ayrılmış navigasyon içeren header',
  type: 'header-10',
  code: 'HDR10',
  category: ['header'],
  suitable: ['header'],
  styleDefault: {
    background: '#ffffff',
    color: '#212529',
  },
  schema: {
    type: 'object',
    required: ['logo', 'leftNavigation', 'rightNavigation'],
    properties: {
      logo: {
        type: 'object',
        title: 'Logo',
        properties: {
          src: { type: 'string', title: 'Logo URL', default: '/logo.svg' },
          alt: { type: 'string', title: 'Logo Alt Metni', default: 'Logo' },
        },
      },
      leftNavigation: {
        type: 'array',
        title: 'Sol Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
      rightNavigation: {
        type: 'array',
        title: 'Sağ Navigasyon Menüsü',
        items: {
          type: 'object',
          properties: {
            text: { type: 'string', title: 'Menü Metni' },
            url: { type: 'string', title: 'Menü URL' },
          },
        },
      },
    },
  },
  propsDefault: async (project: IProject) => ({
    logo: {
      src: project.logo || '/logo.svg', // Projeden logo al
      alt: project.title || 'Logo', // Proje başlığını alt metin olarak al
    },
    leftNavigation: [
      { text: 'Ana Sayfa', url: '#' },
      { text: 'Hakkımızda', url: '#' },
    ],
    rightNavigation: [
      { text: 'Hizmetler', url: '#' },
      { text: 'İletişim', url: '#' },
    ],
  }),
};

export default config;
