import { EditorConfigW } from '../../../../service/Type';
import { AlertBaseProps } from '../alert/types';

export type AlertProps = AlertBaseProps;

const config: EditorConfigW<AlertProps> = {
  title: 'Önemli Uyarılar 3',
  description: 'Tam genişlikte, kurumsal tasarımlı uyarı mesajı bloğu.',
  type: 'alert-3',
  code: 'AL3',
  category: ['alert'],
  suitable: ['header', 'main', 'footer'],
  styleDefault: {
    background: '#fff',
  },
  schema: {
    type: 'object',
    required: ['title', 'description', 'type'],
    properties: {
      title: {
        type: 'string',
        title: 'Başlık',
        default: 'Sistem Güncellemesi',
      },
      description: {
        type: 'string',
        title: 'Açıklama',
        default: 'Sistemimiz 24 Mart 2024 tarihinde 02:00-04:00 saatleri arasında bakımda olacaktır.',
      },
      showButton: {
        type: 'boolean',
        title: 'Buton Göster',
        default: true,
      },
      button: {
        type: 'object',
        title: 'Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
      type: {
        type: 'string',
        title: 'Uyarı Tipi',
        enum: ['warning', 'error', 'success', 'info'],
        default: 'info',
      },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      title: 'Sistem Güncellemesi',
      description: 'Sistemimiz 24 Mart 2024 tarihinde 02:00-04:00 saatleri arasında bakımda olacaktır.',
      showButton: true,
      button: {
        text: 'Detaylı Bilgi',
        url: '#',
      },
      type: 'info',
    };
  },
};

export default config;
