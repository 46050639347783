import { EditorConfigW } from '../../../../service/Type';
import { CTABaseProps } from '../call-to-action/types';
import image from './action.jpg';

export type CTAProps = CTABaseProps;

const config: EditorConfigW<CTAProps> = {
  title: 'Call to Action Bileşeni',
  description: 'Görsel ve çift buton içeren çağrı bileşeni',
  type: 'call-to-action-2',
  code: 'CT2',
  category: ['call-to-action'],
  suitable: ['main', 'footer'],
  styleDefault: {
    background: '#fff',
  },
  schema: {
    type: 'object',
    required: ['title', 'description', 'primaryButton'],
    properties: {
      title: {
        type: 'string',
        title: 'Başlık',
        default: 'İşinizi Büyütmeye Hazır Mısınız?',
      },
      description: {
        type: 'string',
        title: 'Açıklama',
        default: 'Modern çözümlerimizle işletmenizi bir sonraki seviyeye taşıyın. Hemen başlayın ve farkı görün.',
      },
      showSecondaryButton: {
        type: 'boolean',
        title: 'İkinci Buton Göster',
        default: true,
      },
      primaryButton: {
        type: 'object',
        title: 'Ana Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
      secondaryButton: {
        type: 'object',
        title: 'İkincil Buton',
        properties: {
          text: { type: 'string', title: 'Buton Metni' },
          url: { type: 'string', title: 'Buton URL' },
        },
      },
      background: {
        type: 'string',
        title: 'Arka Plan',
        enum: ['light', 'dark', 'gradient', 'color'],
        default: 'light',
      },
      align: {
        type: 'string',
        title: 'Hizalama',
        enum: ['left', 'center', 'right'],
        default: 'center',
      },
      showImage: {
        type: 'boolean',
        title: 'Görsel Göster',
        default: true
      },
      image: {
        type: 'object',
        title: 'Görsel',
        properties: {
          src: { 
            type: 'string', 
            title: 'Görsel URL',
            default: image
          },
          alt: { 
            type: 'string', 
            title: 'Görsel Açıklaması',
            default: 'Call to Action Görseli'
          }
        }
      },
      imagePosition: {
        type: 'string',
        title: 'Görsel Konumu',
        enum: ['left', 'right', 'background'],
        default: 'right'
      },
      theme: {
        type: 'string',
        title: 'Tema',
        enum: ['light', 'dark'],
        default: 'light',
      },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      title: 'İşinizi Büyütmeye Hazır Mısınız?',
      description: 'Modern çözümlerimizle işletmenizi bir sonraki seviyeye taşıyın. Hemen başlayın ve farkı görün.',
      showSecondaryButton: true,
      primaryButton: {
        text: 'Hemen Başla',
        url: '#',
      },
      secondaryButton: {
        text: 'Daha Fazla Bilgi',
        url: '#',
      },
      background: 'light',
      align: 'center',
      showImage: true,
      image: {
        src: '/images/default-cta.jpg',
        alt: 'Call to Action Görseli'
      },
      imagePosition: 'right',
      theme: 'light',
    };
  },
};

export default config;
