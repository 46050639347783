import React from 'react';
import { ViewerPropsT } from '../../../../service/Type';
import { HeaderProps } from './config';

export default ({ props }: ViewerPropsT<HeaderProps>) => {
  const isDark = props.theme === 'dark';

  return (
    <header className={`${isDark ? 'bg-gray-900' : 'bg-white'}`}>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">{props.logo.alt}</span>
            <img className="h-8 w-auto" src={props.logo.src} alt={props.logo.alt} />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${
              isDark ? 'text-gray-400' : 'text-gray-700'
            }`}
          >
            <span className="sr-only">Ana menüyü aç</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {props.megaMenu.map((menu, menuIndex) => (
            <div key={menuIndex} className="relative group">
              <button
                type="button"
                className={`flex items-center gap-x-1 text-sm font-semibold leading-6 ${
                  isDark ? 'text-white' : 'text-gray-900'
                }`}
              >
                {menu.title}
                <svg className="h-5 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
              </button>
              <div className="absolute hidden group-hover:block pt-3 -ml-4">
                <div className={`rounded-xl p-4 ${isDark ? 'bg-gray-800' : 'bg-white'} shadow-lg ring-1 ring-gray-900/5`}>
                  {menu.items.map((item, itemIndex) => (
                    <a
                      key={itemIndex}
                      href={item.url}
                      className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                    >
                      {item.icon && (
                        <div className={`mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg ${isDark ? 'bg-gray-700' : 'bg-gray-50'} group-hover:bg-white`}>
                          <span className="h-6 w-6 text-gray-600 group-hover:text-indigo-600">{item.icon}</span>
                        </div>
                      )}
                      <div>
                        <div className={`font-semibold ${isDark ? 'text-white' : 'text-gray-900'}`}>
                          {item.text}
                        </div>
                        {item.description && (
                          <p className="mt-1 text-gray-600">{item.description}</p>
                        )}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            href={props.actionButton.url}
            className={`text-sm font-semibold leading-6 ${
              isDark ? 'text-white hover:text-gray-300' : 'text-gray-900 hover:text-gray-700'
            }`}
          >
            {props.actionButton.text} <span aria-hidden="true">→</span>
          </a>
        </div>
      </nav>
    </header>
  );
};
